var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content continuing-education",
    class: _vm.device + "-corporate-culture",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/culture_banner_pc.jpg")
    }
  }), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }],
    attrs: {
      src: require("../assets/banner/culture_banner_mobile.jpg")
    }
  })]), _c("section", {
    staticClass: "continuing-education-content",
    staticStyle: {
      background: "#f6f6f8"
    }
  }, [_c("div", {
    staticClass: "newsBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("文化与人才")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Culture and talent")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("文化與人才")]) : _vm._e(), _c("div", {
    staticClass: "line"
  })]), _c("ul", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "article-list article-list-culture"
  }, _vm._l(_vm.article_list, function (item, index) {
    return _c("li", {
      key: index,
      staticClass: "article-culture-li",
      attrs: {
        "data-id": item.id
      }
    }, [_c("router-link", {
      staticClass: "article-a",
      attrs: {
        to: {
          path: "articleDetail",
          query: {
            id: item.id
          }
        }
      }
    }, [_c("div", {
      staticClass: "article-img-box"
    }, [_c("img", {
      staticClass: "article-img",
      attrs: {
        src: _vm.title + item.newsTitlePic
      }
    })]), _c("div", {
      staticClass: "article-text"
    }, [_c("h3", {
      staticClass: "article-title"
    }, [_vm._v(_vm._s(item.newsTitle))])])])], 1);
  }), 0), _vm.language == 1 ? _c("section", {
    staticClass: "page-box space-around"
  }, [_c("span", [_vm._v("每页 " + _vm._s(_vm.page_size) + " 条")]), _c("span", [_vm._v("共 " + _vm._s(_vm.total_page) + " 页")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(-1);
      }
    }
  }, [_vm._v("上一页")]), _c("span", [_vm._v("第 " + _vm._s(_vm.page_num) + " 页")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(+1);
      }
    }
  }, [_vm._v("下一页")])]) : _vm._e(), _vm.language == 2 ? _c("section", {
    staticClass: "page-box space-around"
  }, [_c("span", [_vm._v(_vm._s(_vm.page_size) + " entries per page")]), _c("span", [_vm._v("total " + _vm._s(_vm.total_page) + " page")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(-1);
      }
    }
  }, [_vm._v("page up")]), _c("span", [_vm._v("current " + _vm._s(_vm.page_num))]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(+1);
      }
    }
  }, [_vm._v("page down")])]) : _vm._e(), _vm.language == 3 ? _c("section", {
    staticClass: "page-box space-around"
  }, [_c("span", [_vm._v("每頁 " + _vm._s(_vm.page_size) + " 條")]), _c("span", [_vm._v("共 " + _vm._s(_vm.total_page) + " 頁")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(-1);
      }
    }
  }, [_vm._v("上一頁")]), _c("span", [_vm._v("第 " + _vm._s(_vm.page_num) + " 頁")]), _c("span", {
    on: {
      click: function ($event) {
        return _vm.changePage(+1);
      }
    }
  }, [_vm._v("下一頁")])]) : _vm._e()])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };